
import { Component, Vue } from "vue-property-decorator";
import { Device } from "@capacitor/device";

type Device = {
  id: number;
  fcm_token_active: boolean;
};

@Component
export default class PushNotificationsComponent extends Vue {
  currentDevice: null | Device = null;

  get fcmTokenActive() {
    return this.currentDevice ? this.currentDevice.fcm_token_active : false;
  }

  set fcmTokenActive(fcmTokenActive: boolean) {
    if (this.currentDevice) {
      this.$store.dispatch("device/updateFcmTokenActive", {
        id: this.currentDevice.id,
        fcm_token_active: fcmTokenActive
      });
    }
  }

  get deviceId() {
    return this.$store.getters["device/deviceId"];
  }

  created() {
    this.fetchCurrentDevice();
  }

  async fetchCurrentDevice() {
    this.$store
      .dispatch("device/fetchCurrent", this.deviceId.identifier)
      .then(({ data }) => {
        this.currentDevice = {
          id: data.id,
          fcm_token_active: data.fcm_token_active
        };
      });
  }
}
